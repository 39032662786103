import React, { useState } from 'react'

import * as Y from 'yjs'

import { toYType } from 'features/utils/useYjsState'
import * as yUtils from 'features/utils/yUtils'
import { WidgetAdminControlsComponent, WidgetComponent } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { OrderableListSelector } from 'v2/ui/components/OrderableListSelector/OrderableListSelector'
import { Item } from 'v2/ui/components/OrderableListSelector/types'
import useDebounce from 'v2/ui/utils/useDebounce'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { Icon } from 'ui/components/Icon'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Skeleton } from 'ui/components/Skeleton'
import { Spinner } from 'ui/components/Spinner'
import { Body, Headline } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'
import { Toggle } from 'ui/components/Toggle'
import { Tooltip } from 'ui/components/Tooltip'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { usePipelineWidgetItemListState } from './hooks/usePipelineWidgetItemListState'
import { usePipelineWidgetStages } from './hooks/usePipelineWidgetStages'
import { PipelineWidgetStage, PipelineWidgetStageData, PipelineWidgetType } from './types'
import { getAvailableStages, getSupportedFields } from './utils'

import {
    PipelineWidgetHorizontalItemIconStyles,
    PipelineWidgetHorizontalItemStyles,
    PipelineWidgetListStyles,
    PipelineWidgetStyle,
    PipelineWidgetVerticalItemHeadlineStyle,
    PipelineWidgetVerticalItemIconWrapperStyles,
    PipelineWidgetVerticalItemStyles,
} from './PipelineWidget.css'

type PipelineWidgetProps = {}

export const PipelineWidget: WidgetComponent<PipelineWidgetType, PipelineWidgetProps> = ({
    widget,
    isEditing,
}) => {
    return (
        <Box py="l" pointerEvents={isEditing ? 'none' : undefined} className={PipelineWidgetStyle}>
            <PipelineWidgetItemList widget={widget} />
        </Box>
    )
}

type PipelineWidgetItemListProps = {
    widget: PipelineWidgetType
}

const PipelineWidgetItemList: React.FC<PipelineWidgetItemListProps> = ({ widget }) => {
    const { style } = widget.attrs
    const { items, currentStageIdx, onStageClick, isLoading, isReadOnly, isVisible, isSavingSlow } =
        usePipelineWidgetItemListState(widget)

    if (!isVisible) return null

    return (
        <Skeleton isLoading={isLoading} display="flex">
            <Box
                className={PipelineWidgetListStyles.styleFunction({
                    style,
                })}
            >
                {items.map((item, idx) => {
                    let status: PipelineWidgetHorizontalItemProps['status'] = 'upcoming'
                    if (idx === currentStageIdx) {
                        status = 'current'
                    } else if (idx < currentStageIdx) {
                        status = 'completed'
                    }

                    const isFirst = idx === 0
                    const isLast = idx === items.length - 1

                    if (style === 'horizontal') {
                        return (
                            <PipelineWidgetHorizontalItem
                                key={item.stage.id}
                                item={item}
                                isFirst={isFirst}
                                isLast={isLast}
                                status={status}
                                onClick={onStageClick}
                                isReadOnly={isReadOnly}
                                isSavingSlow={isSavingSlow}
                            />
                        )
                    }

                    return (
                        <PipelineWidgetVerticalItem
                            key={item.stage.id}
                            item={item}
                            status={status}
                            onClick={onStageClick}
                            isReadOnly={isReadOnly}
                            isSavingSlow={isSavingSlow}
                            isLast={isLast}
                        />
                    )
                })}
            </Box>
        </Skeleton>
    )
}

type PipelineWidgetHorizontalItemProps = {
    item: PipelineWidgetStageData
    isFirst?: boolean
    isLast?: boolean
    status?: 'completed' | 'current' | 'upcoming'
    onClick?: (stageId: string) => void
    isReadOnly?: boolean
    isSavingSlow?: boolean
}

const PipelineWidgetHorizontalItem: React.FC<PipelineWidgetHorizontalItemProps> = ({
    item,
    isFirst,
    isLast,
    status,
    onClick,
    isReadOnly,
    isSavingSlow,
}) => {
    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    const { description } = item.stage
    const hasDescription = !!description

    const hasTooltip = hasDescription && !isMobile
    const isEditable = !isReadOnly

    const content = (
        <Box
            as={isEditable ? 'button' : undefined}
            className={PipelineWidgetHorizontalItemStyles.styleFunction({
                isFirst,
                isLast,
                status,
                color: item.color as any,
            })}
            onClick={isEditable ? () => onClick?.(item.stage.id) : undefined}
        >
            {status === 'current' && isSavingSlow && <Spinner size="s" noShrink color="inverse" />}
            {status === 'completed' && !isSavingSlow && (
                <Icon
                    size="m"
                    name="Check"
                    noShrink
                    className={PipelineWidgetHorizontalItemIconStyles.styleFunction({
                        color: item.color as any,
                    })}
                />
            )}
            <Box minWidth={0} trim>
                <Body size="m" weight="medium">
                    {item.label}
                </Body>
            </Box>
        </Box>
    )

    if (hasTooltip) {
        return (
            <Tooltip content={description} asChild zIndex={200} side="bottom">
                {content}
            </Tooltip>
        )
    }

    return content
}

type PipelineWidgetVerticalItemProps = {
    item: PipelineWidgetStageData
    status?: 'completed' | 'current' | 'upcoming'
    onClick?: (stageId: string) => void
    isReadOnly?: boolean
    isSavingSlow?: boolean
    isLast?: boolean
}

const PipelineWidgetVerticalItem: React.FC<PipelineWidgetVerticalItemProps> = ({
    item,
    status,
    onClick,
    isReadOnly,
    isSavingSlow,
    isLast,
}) => {
    const isEditable = !isReadOnly && status !== 'current'

    return (
        <Box
            className={PipelineWidgetVerticalItemStyles.styleFunction({
                status,
                color: item.color as any,
            })}
        >
            <Box
                as={isEditable ? 'button' : undefined}
                onClick={isEditable ? () => onClick?.(item.stage.id) : undefined}
                className={PipelineWidgetVerticalItemIconWrapperStyles.styleFunction({
                    status,
                    color: item.color as any,
                    isLast,
                })}
                noShrink
            >
                {status === 'current' && isSavingSlow && <Spinner size="xs" noShrink />}
                {status === 'completed' && !isSavingSlow && <Icon size="s" name="Check" noShrink />}
            </Box>
            <Box minWidth={0} pb="m">
                <Box
                    as={isEditable ? 'button' : undefined}
                    onClick={isEditable ? () => onClick?.(item.stage.id) : undefined}
                    trim
                    minWidth={0}
                    className={PipelineWidgetVerticalItemHeadlineStyle}
                >
                    <Headline
                        size="3xs"
                        maxLines={1}
                        trim
                        color={status === 'upcoming' ? 'textWeakest' : 'text'}
                    >
                        {item.label}
                    </Headline>
                </Box>
                {!!item.stage.description && (
                    <Body
                        size="s"
                        color={status === 'upcoming' ? 'textWeakest' : 'textWeaker'}
                        maxLines={1}
                        display="block"
                        trim
                        minWidth={0}
                    >
                        {item.stage.description}
                    </Body>
                )}
            </Box>
        </Box>
    )
}

type PipelineWidgetAdminControlsProps = {}

export const PipelineWidgetAdminControls: WidgetAdminControlsComponent<
    PipelineWidgetType,
    PipelineWidgetAdminControlsProps
> = ({ widget, onChange, openDetailPane }) => {
    const { isReadOnly, style, field, stages = [] } = widget.attrs

    const onChangeIsReadOnly = (checked: boolean) => {
        onChange((attrs) => {
            attrs.set('isReadOnly', checked)
        })
    }

    const onChangeStyle = (value: string) => {
        onChange((attrs) => {
            attrs.set('style', value)
        })
    }

    const { fields } = useLayoutEditorContext()
    const supportedFields = getSupportedFields(fields)

    let fieldApiName = field?.fieldApiName ?? ''
    const selectedField = fields?.find((f) => f.api_name === field?.fieldApiName)
    // If the field is not in the list of fields, reset the fieldApiName.
    if (!selectedField) {
        fieldApiName = ''
    }
    const configuredStages = stages.reduce((acc, stage) => {
        return acc.set(stage.id, stage)
    }, new Map<string, PipelineWidgetStage>())

    const onChangeField = (value: string) => {
        onChange((attrs) => {
            attrs.set(
                'field',
                toYType({
                    type: 'field',
                    fieldApiName: value,
                })
            )

            const newField = fields?.find((f) => f.api_name === value)
            const newStages = getAvailableStages(configuredStages, newField).map((s) => ({
                ...s.stage,
                isActive: true,
            }))

            // Reset the stages when the field changes.
            attrs.set('stages', toYType(newStages))
        })
    }

    const { allStages, activeStages } = usePipelineWidgetStages(widget)

    const onEditStage = (stageId: string) => {
        openDetailPane({
            component: (props) => <StageDetailsControl {...props} stageId={stageId} />,
            label: 'Stage: Details',
        })
    }

    const onInsert = (item: Item) => {
        onChange((attrs) => {
            const addedItem = allStages.find((stage) => stage.stage.id === item.id)
            if (!addedItem) return

            const stages = attrs.get('stages')
            if (stages) {
                const stage = yUtils.arrayFind<Y.Map<any>>(stages, (t) => t.get('id') === item.id)
                if (!!stage) {
                    stage.set('isActive', true)
                }
            } else {
                const addedYItem = toYType({ ...addedItem.stage, isActive: true })
                attrs.set('stages', Y.Array.from([addedYItem]))
            }
        })
    }

    const onUpdate = (items: Item[]) => {
        const activeItems = items.reduce((acc, item, idx) => {
            return acc.set(item.id, { ...item, idx })
        }, new Map<string, Item & { idx: number }>())

        onChange((attrs) => {
            const newStages = allStages.reduce((acc, stage) => {
                const activeItem = activeItems.get(stage.stage.id)

                acc.push({
                    ...stage.stage,
                    idx: activeItem?.idx ?? -1,
                    isActive: !!activeItem,
                })

                return acc
            }, [] as PipelineWidgetStage[])
            newStages.sort((a, b) => a.idx - b.idx)

            attrs.set('stages', toYType(newStages))
        })
    }

    return (
        <Box flex column px="l" gap="3xl" height="full" overflowY="auto">
            <Select
                placeholder="Select field..."
                label="Field"
                isSearchable
                value={fieldApiName}
                onChange={onChangeField}
            >
                {supportedFields.map((field) => (
                    <SelectOption key={field.api_name} value={field.api_name} label={field.label} />
                ))}
            </Select>
            {fieldApiName && (
                <>
                    <Field label="Style">
                        <RadioCardGroup
                            value={style}
                            onValueChange={onChangeStyle}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                                gap: theme.space.m,
                            }}
                        >
                            <RadioCard value="horizontal" icon={{ name: 'Columns3' }}>
                                Horizontal
                            </RadioCard>
                            <RadioCard value="vertical" icon={{ name: 'Rows3' }}>
                                Vertical
                            </RadioCard>
                        </RadioCardGroup>
                    </Field>
                    <Field label="Stages">
                        <OrderableListSelector
                            maxHeight="250px"
                            style={{
                                marginBottom: '-16px',
                            }}
                            onAdd={onInsert}
                            items={allStages.map(({ stage, label }) => ({
                                name: label,
                                label,
                                id: stage.id,
                            }))}
                            selectedItems={activeStages.map(({ stage, label }) => ({
                                name: label,
                                label,
                                id: stage.id,
                                actions: [
                                    {
                                        icon: 'edit',
                                        onClick: () => onEditStage(stage.id),
                                        color: 'gray.300',
                                        title: 'Edit stage',
                                    },
                                ],
                            }))}
                            onUpdate={onUpdate}
                        />
                    </Field>
                    <Field
                        htmlFor="isReadOnly"
                        label="Read-only"
                        rightSlotContent={
                            <Toggle
                                id="isReadOnly"
                                checked={isReadOnly}
                                onCheckedChange={onChangeIsReadOnly}
                            />
                        }
                    />
                </>
            )}
        </Box>
    )
}

const DEBOUNCE_RATE = 300 // ms

type StageDetailsControlProps = {
    stageId: string
}

const StageDetailsControl: WidgetAdminControlsComponent<
    PipelineWidgetType,
    StageDetailsControlProps
> = ({ widget, onChange, stageId }) => {
    const { allStages } = usePipelineWidgetStages(widget)

    const stage = allStages.find((stage) => stage.stage.id === stageId)

    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const [localDescription, setLocalDescription] = useState(stage?.stage.description ?? '')
    const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value || ''

        setLocalDescription(value)
        debouncedOnChange((attrs) => {
            const stages = attrs.get('stages')
            if (!stages) return

            const stage = yUtils.arrayFind<Y.Map<any>>(stages, (t) => t.get('id') === stageId)
            if (!!stage) {
                stage.set('description', value)
            }
        })
    }

    return (
        <Box px="l" height="full" overflowY="auto">
            <Textarea
                label="Description"
                name="description"
                size="m"
                autoFocus
                resizable
                value={localDescription}
                onChange={onChangeDescription}
            />
        </Box>
    )
}
