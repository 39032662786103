import { useCallback, useEffect, useRef, useState } from 'react'

import isEqual from 'fast-deep-equal'
import * as Y from 'yjs'

import { useGetRecord } from 'data/hooks/records'
import {
    deleteRecord as deleteRecordOperation,
    invalidateRecord,
    updateRecord,
} from 'data/hooks/records/recordOperations'
import { useYjsState } from 'features/utils/useYjsState'
import {
    isUsersRecord,
    useUserRecordLinksAvatars,
} from 'features/views/attributes/hooks/useUserRecordLinksAvatars'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useToast } from 'ui/components/Toast'

const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

export type RecordManager = {
    record?: RecordDto
    includeFields?: string[]
    dereferencedRecords?: RecordDto[]
    permissions: {
        canDelete: boolean
        canUpdateFields: string[]
        canReadFields: string[]
    }
    isLoading: boolean
    isFetchingSlow: boolean
    isError: boolean
    isSaving: boolean
    mutateRecord: (tr: (record: Y.Map<any>) => void) => void
    replaceAttribute: (fieldApiName: string, value: Y.AbstractType<any>) => void
    mutateAttribute: (
        fieldApiName: string,
        tr: (value: Y.AbstractType<any>) => Y.AbstractType<any> | undefined
    ) => void
    clearAttribute: (fieldApiName: string) => void
    discardChanges: () => void
    deleteRecord: () => void
    saveChanges: () => Promise<void>
    isDirty: boolean
}

type UseRecordManagerStateOptions = {
    recordSid?: string
    includeFields?: string[]
}

export function useRecordManagerState(options: UseRecordManagerStateOptions): RecordManager {
    const { recordSid, includeFields } = options

    // Store included fields as a string to avoid unnecessary re-renders.
    const includedFieldsStr = includeFields?.join(',')
    const [includedFields, setIncludedFields] = useState(
        getIncludedFieldsFromString(includedFieldsStr)
    )
    const includeFieldsRef = useRef(includedFields)
    includeFieldsRef.current = includedFields
    useEffect(() => {
        setIncludedFields(getIncludedFieldsFromString(includedFieldsStr))
    }, [includedFieldsStr])

    const {
        data: originalRecord,
        isFetching,
        isError,
    } = useGetRecord({
        recordId: recordSid!,
        options: { dereference: true },
        useQueryOptions: {
            refetchOnMount: 'always',
            enabled: !!recordSid,
        },
        includeFields: includedFields,
    })
    const originalRecordMemo = useDeepEqualsMemoValue(originalRecord)
    const originalRecordRef = useRef(originalRecordMemo)
    originalRecordRef.current = originalRecordMemo

    const {
        data: record,
        replaceValue,
        applyTransaction,
    } = useYjsState<RecordDto>(originalRecordMemo ?? ({} as RecordDto))
    const updatedRecord = !!originalRecord && Object.values(record).length > 0 ? record : undefined
    const updatedRecordRef = useRef(updatedRecord)
    updatedRecordRef.current = updatedRecord

    // Sync the record with the original record when the original record changes.
    useEffect(() => {
        replaceValue(originalRecordMemo ?? ({} as RecordDto))
    }, [originalRecordMemo, replaceValue])

    const [isFetchingSlow, setIsFetchingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isFetching) {
            timer = window.setTimeout(() => {
                setIsFetchingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsFetchingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isFetching])

    const isLoading = !originalRecord

    const replaceAttribute: RecordManager['replaceAttribute'] = useCallback(
        (fieldApiName, value) => {
            applyTransaction((record) => {
                record.set(fieldApiName, value)
            })
        },
        [applyTransaction]
    )

    const mutateAttribute: RecordManager['mutateAttribute'] = useCallback(
        (fieldApiName, tr) => {
            applyTransaction((record) => {
                const value = record.get(fieldApiName)
                const replaceValue = tr(value)
                record.set(fieldApiName, replaceValue)
            })
        },
        [applyTransaction]
    )

    const clearAttribute: RecordManager['clearAttribute'] = useCallback(
        (fieldApiName) => {
            applyTransaction((record) => {
                record.set(fieldApiName, null)
            })
        },
        [applyTransaction]
    )

    const [isSaving, setIsSaving] = useState(false)
    const toast = useToast()

    const saveChanges: RecordManager['saveChanges'] = useCallback(async () => {
        // Only update the record if it has changed.
        const isDirty = isDirtyRef.current
        if (!isDirty) return Promise.resolve()

        const updatedRecord = updatedRecordRef.current
        if (!updatedRecord) return Promise.resolve()

        setIsSaving(true)

        try {
            await updateRecord(updatedRecord._sid, { ...updatedRecord }, includeFieldsRef.current, {
                deferStoreUpdate: true,
            })
            invalidateRecord(updatedRecord._sid)
        } catch {
            toast({
                type: 'error',
                startIcon: {
                    name: 'AlertCircle',
                },
                title: 'There was a problem saving the record',
                helperText: 'Please try again later. If the issue persists, contact support.',
            })
        } finally {
            setIsSaving(false)
        }
    }, [toast])

    const discardChanges: RecordManager['discardChanges'] = useCallback(() => {
        const originalRecord = originalRecordRef.current
        replaceValue(originalRecord ?? ({} as RecordDto))
    }, [replaceValue])

    const isDirty = checkIsRecordDirty(record, originalRecord)
    const isDirtyRef = useRef(isDirty)
    isDirtyRef.current = isDirty

    const deleteRecord: RecordManager['deleteRecord'] = useCallback(() => {
        if (!recordSid) return

        deleteRecordOperation(recordSid)
    }, [recordSid])

    const dereferencedRecords = useDereferencedRecords(originalRecord)

    const permissions = {
        canDelete: originalRecord?._permissions?.may_delete ?? false,
        canUpdateFields: originalRecord?._permissions?.may_update_fields ?? [],
        canReadFields: originalRecord?._permissions?.may_read_fields ?? [],
    }
    if (!originalRecord?._permissions?.may_update) {
        permissions.canUpdateFields = []
    }

    return useDeepEqualsMemoValue({
        record: updatedRecord,
        isLoading,
        isFetchingSlow,
        isError,
        mutateRecord: applyTransaction,
        replaceAttribute,
        mutateAttribute,
        clearAttribute,
        isSaving,
        saveChanges,
        discardChanges,
        deleteRecord,
        isDirty,
        dereferencedRecords,
        permissions,
        includeFields: includedFields,
    })
}

function checkIsRecordDirty(record?: RecordDto, originalRecord?: RecordDto) {
    return !isEqual(record, originalRecord)
}

function useDereferencedRecords(record?: RecordDto): RecordDto[] {
    const records = record?._dereferenced_records as RecordDto[] | undefined

    // Dereferenced users don't have avatars, so we need to fetch them separately.
    const usersObject = useStackerUsersObject()
    const userRecordSids = records?.reduce((acc, record) => {
        if (isUsersRecord(record, usersObject)) {
            acc.push(record._sid)
        }

        return acc
    }, [] as string[])

    const { records: userRecords } = useUserRecordLinksAvatars(userRecordSids)
    const userRecordsMap = userRecords?.reduce(
        (acc, record) => acc.set(record._sid, record),
        new Map<string, RecordDto>()
    )

    const allRecords = [...(records ?? [])]
    if (!!record) {
        allRecords.push(record)
    }

    const finalDereferencedRecords = allRecords.map((record) => {
        const userOverride = userRecordsMap?.get(record._sid)
        if (userOverride) {
            return userOverride
        }

        return record
    })

    return useDeepEqualsMemoValue(finalDereferencedRecords)
}

function getIncludedFieldsFromString(includedFieldsStr?: string) {
    if (!includedFieldsStr) return undefined

    return includedFieldsStr.split(',').filter(Boolean).sort()
}
