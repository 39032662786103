import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import {
    PipelineWidgetStage,
    PipelineWidgetType,
} from 'features/views/LayoutEditor/widgets/PipelineWidget/types'
import { getAvailableStages } from 'features/views/LayoutEditor/widgets/PipelineWidget/utils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function usePipelineWidgetStages(widget: PipelineWidgetType) {
    const { stages = [], field } = widget.attrs

    const { fields } = useLayoutEditorContext()

    const selectedField = fields?.find((f) => f.api_name === field?.fieldApiName)

    const configuredStages = stages.reduce((acc, stage) => {
        return acc.set(stage.id, stage)
    }, new Map<string, PipelineWidgetStage>())

    const allStages = getAvailableStages(configuredStages, selectedField)

    const activeStages = allStages.filter((s) => {
        const stage = configuredStages.get(s.stage.id)
        return stage?.isActive ?? false
    })
    activeStages.sort((a, b) => a.stage.idx - b.stage.idx)

    return useDeepEqualsMemoValue({
        allStages,
        activeStages,
    })
}
