import React from 'react'

import { DetailViewContent } from './DetailViewContent'
import { DetailViewContextProvider } from './DetailViewContext'

type DetailViewProps = {
    isEmbedded?: boolean
    recordSid?: string
    hideHeader?: boolean
}

export const DetailView: React.FC<DetailViewProps> = ({
    isEmbedded = false,
    recordSid,
    hideHeader,
}) => {
    return (
        <DetailViewContextProvider isEmbedded={isEmbedded} recordSid={recordSid}>
            <DetailViewContent hideHeader={hideHeader} />
        </DetailViewContextProvider>
    )
}
