import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import styled from '@emotion/styled'

import { useDeleteAction } from 'data/hooks/actions'
import ActionEditor from 'features/actions/ActionEditor'
import { getNewAction } from 'features/actions/getNewAction'
import { useActionsFromObjectId } from 'features/actions/helpers'
import ObjectFieldsFilter from 'features/records/components/RecordFilters'

import {
    Box,
    Button,
    ConfirmationModal,
    Divider,
    Flex,
    Modal,
    OrderableListSelector,
    Slide,
    Text,
} from 'v2/ui'
import { Item } from 'v2/ui/components/OrderableListSelector/types'
import { Zap } from 'v2/ui/svgs'

import { isSystemAction } from './utils/isSystemAction'

type HideOn = React.ComponentPropsWithoutRef<typeof Slide>['hideOn']

const StaticSlide = styled.div<{ hideOn?: HideOn }>`
    display: ${({ hideOn }) => (hideOn ? 'none' : 'block')};
`

type ActionItem = Item & {
    conditions?: Filter[]
}

type ActionButtonsSelectorProps = React.ComponentPropsWithoutRef<typeof Flex> & {
    selectedButtons: ActionButton[]
    setConfig: (config: Partial<ListViewOptions> & { pageButtons: ActionButton[] }) => void
    object?: ObjectDto
    additionalActions?: ActionDto[]
    editor?: 'fields' | null
    animate?: boolean
    showHeader?: boolean
    showObjectActions?: boolean
    canDeleteActions?: boolean
    canEditActions?: boolean
    canSearchActions?: boolean
    canReorderActions?: boolean
    provideIcon?: (item: Item) => string | JSX.Element | null
    customHeader?: ({ onCreateActionClick }: { onCreateActionClick: () => void }) => React.ReactNode
}

const ActionButtonsSelector: React.FC<ActionButtonsSelectorProps> = ({
    object,
    setConfig,
    selectedButtons,
    additionalActions = [],
    editor = null,
    animate = true,
    showHeader = true,
    canDeleteActions = true,
    canEditActions = true,
    canSearchActions = true,
    showObjectActions = true,
    canReorderActions = true,
    provideIcon,
    customHeader,
    ...props
}) => {
    const { data: actions = [] } = useActionsFromObjectId(object?._sid ?? '')
    const [creatingAction, setCreatingAction] = useState<ActionDto | null>(null)
    const [editingAction, setEditingAction] = useState<ActionItem | null>(null)
    const [actionToDelete, setActionToDelete] = useState<ActionItem | null>(null)
    const editConditions = (item: ActionItem) => {
        setEditingAction(item)
    }

    const onActionDelete = (item: ActionButton) => setActionToDelete(item)

    const { mutateAsync: deleteAction } = useDeleteAction()
    const [isDeleting, setIsDeleting] = useState(false)

    const onConfirmDelete = () => {
        if (!actionToDelete) return

        setIsDeleting(true)
        deleteAction({ id: actionToDelete.id }).then(() => {
            setIsDeleting(false)
            setActionToDelete(null)
        })
    }

    const allActions = useMemo(() => {
        if (showObjectActions) {
            return additionalActions.concat(actions)
        }

        return additionalActions
    }, [showObjectActions, additionalActions, actions])

    const actionsBase: ActionItem[] = useMemo(
        () =>
            allActions.map((action) => {
                const isSelected = !!selectedButtons.find((button) => button.id === action._sid)
                return {
                    id: action._sid,
                    label: action.name,
                    actions:
                        canDeleteActions &&
                        !isSelected &&
                        action._sid &&
                        !isSystemAction(action._sid)
                            ? [
                                  {
                                      icon: 'trash',
                                      onClick: onActionDelete,
                                      color: 'gray.300',
                                      title: 'Delete Action',
                                  },
                              ]
                            : [],
                } as ActionItem
            }),
        [canDeleteActions, allActions, selectedButtons]
    )
    const getItemWrapper = useCallback(
        (item: Partial<ActionItem>): ActionItem => {
            const result: ActionItem = {
                id: '',
                ...item,
                actions: [
                    {
                        icon: 'eye',
                        onClick: editConditions,
                        color: item.conditions?.length === 0 ? 'gray.300' : 'adminBrand',
                        title: 'Edit conditional visibility',
                    },
                ],
            }

            // option to edit real actions, excluding system ones
            if (canEditActions && item.id && !isSystemAction(item.id)) {
                result.actions!.unshift({
                    icon: 'edit',
                    onClick: ({ id }) =>
                        setCreatingAction(actions.find(({ _sid }) => id === _sid) ?? null),
                    color: 'gray.300',
                    title: 'Edit Action',
                })
            }

            return result
        },
        [canEditActions, actions]
    )

    // Initialize items with wrappers, but only include items
    // that currently have action definitions available
    // (actions may have been deleted)
    const [items, setItems] = useState<ActionItem[]>(
        selectedButtons
            .filter((x) => allActions.find((action) => action._sid === x.id))
            .map(getItemWrapper) || []
    )

    // we store actions in [items]=useState(), we have to update them when actions change
    // otherwise next time we update them, it will be an older version
    const itemsUpdateCountRef = useRef(0)
    useEffect(() => {
        itemsUpdateCountRef.current++

        // do not update on first render
        if (itemsUpdateCountRef.current > 1) {
            setItems((items) => items.map((item) => getItemWrapper(item)))
        }
    }, [getItemWrapper])

    const handleUpdate = useCallback(
        (items: ActionItem[]) => {
            setItems(items)

            setConfig({
                pageButtons: items.map((x) => ({ id: x.id, conditions: x.conditions ?? [] })),
            })
        },
        [setConfig]
    )
    const handleUpdateItem = useCallback(
        (item: ActionItem) => {
            const newItems = items.map((x) => (x.id === item.id ? getItemWrapper(item) : x))
            setItems(newItems)
            setConfig({
                pageButtons: newItems.map((x) => ({ id: x.id, conditions: x.conditions ?? [] })),
            })
        },
        [getItemWrapper, items, setConfig]
    )
    const handleAdd = useCallback(
        (item: ActionItem) => {
            handleUpdate([...items, getItemWrapper({ id: item.id })])
        },
        [getItemWrapper, handleUpdate, items]
    )

    const handleCreateAction = () => {
        if (!object) return

        setCreatingAction(getNewAction(object._sid))
    }

    const SlideComponent = animate ? Slide : StaticSlide

    return (
        <>
            <Flex
                column
                height={`calc(100% - 80px)`}
                maxHeight="100%"
                wrap="nowrap"
                align="stretch"
                minHeight={0}
                {...props}
            >
                {showHeader && !customHeader && (
                    <Flex mt={2} wrap="nowrap" justify="space-between">
                        <Text size="sm" variant="paletteSectionLabel">
                            Select actions
                        </Text>

                        <Button
                            icon="add"
                            buttonSize="small"
                            variant="Primary"
                            onClick={handleCreateAction}
                        >
                            Add new
                        </Button>
                    </Flex>
                )}
                {showHeader && customHeader?.({ onCreateActionClick: handleCreateAction })}
                <Box
                    position="relative"
                    mt={showHeader && !customHeader ? 4 : 0}
                    height="100%"
                    maxHeight="100%"
                    flexGrow={1}
                    minHeight={0}
                >
                    <SlideComponent>
                        <OrderableListSelector
                            items={actionsBase}
                            onAdd={handleAdd}
                            selectedItems={items}
                            onUpdate={handleUpdate}
                            editor={editor}
                            hideSearch={!canSearchActions}
                            disableReorder={!canReorderActions}
                            provideIcon={provideIcon}
                        />
                    </SlideComponent>
                </Box>
            </Flex>
            {editingAction && (
                <EditConditionsModal
                    item={editingAction}
                    onUpdate={handleUpdateItem}
                    object={object}
                    onClose={() => setEditingAction(null)}
                />
            )}
            {creatingAction && (
                <ActionEditor
                    object={object}
                    item={creatingAction}
                    onRequestClose={() => setCreatingAction(null)}
                />
            )}
            {actionToDelete && (
                <ConfirmationModal
                    icon={<Zap />}
                    isOpen={!!actionToDelete}
                    inProgress={isDeleting}
                    onConfirm={onConfirmDelete}
                    onClose={() => setActionToDelete(null)}
                    title={`Delete action ${actionToDelete?.label}?`}
                    details="Are you sure you want to delete this action?"
                    warning="This cannot be undone."
                />
            )}
        </>
    )
}

type EditConditionsModalProps = {
    object?: ObjectDto
    item: ActionItem
    onUpdate: (item: ActionItem) => void
    onClose: () => void
}

const EditConditionsModal: React.FC<EditConditionsModalProps> = ({
    object,
    item,
    onUpdate,
    onClose,
}) => {
    const [conditions, setConditions] = useState(item.conditions)
    const save = () => {
        onUpdate({ ...item, conditions })
        onClose()
    }

    return (
        <Modal
            isOpen={Boolean(item)}
            title="Edit conditions"
            onClose={onClose}
            size="750px"
            contentOverflow="auto"
        >
            <Flex column align="stretch">
                <Text>Select the conditions under which this button should be visible.</Text>
                <Divider my={2} />
                <Box flexGrow={1} maxWidth={'100%'}>
                    {/*@ts-expect-error*/}
                    <ObjectFieldsFilter
                        object={object}
                        value={conditions}
                        showRoleFilter
                        fields={object?.fields.filter(
                            (field) => !field.connection_options.is_disabled
                        )}
                        onChange={setConditions}
                    />
                </Box>
                <Divider my={2} />
                <Flex justify="flex-end">
                    <Button variant="moderateSm" icon="x" onClick={onClose} mr={1}>
                        Cancel
                    </Button>
                    <Button buttonSize="sm" icon="checkmark" onClick={save}>
                        Save
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    )
}

export default ActionButtonsSelector
