import { StackerLDFlagSet } from 'data/hooks/useLDFlags'
import { LayoutEditorSchema } from 'features/views/LayoutEditor/types'
import { AgentWidgetMeta } from 'features/views/LayoutEditor/widgets/AgentWidget/AgentWidgetMeta'
import { BannerWidgetMeta } from 'features/views/LayoutEditor/widgets/BannerWidgetMeta'
import { DividerWidgetMeta } from 'features/views/LayoutEditor/widgets/DividerWidgetMeta'
import { EmbedWidgetMeta } from 'features/views/LayoutEditor/widgets/EmbedWidgetMeta'
import { FeedWidgetMeta } from 'features/views/LayoutEditor/widgets/FeedWidgetMeta'
import { FieldsWidgetMeta } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidgetMeta'
import { LinkBlocksWidgetMeta } from 'features/views/LayoutEditor/widgets/LinkBlocksWidget/meta'
import { ListViewWidgetMeta } from 'features/views/LayoutEditor/widgets/ListViewWidget/ListViewWidgetMeta'
import { PipelineWidgetMeta } from 'features/views/LayoutEditor/widgets/PipelineWidget/meta'
import { SectionWidgetMeta } from 'features/views/LayoutEditor/widgets/SectionWidgetMeta'
import { TabsWidgetMeta } from 'features/views/LayoutEditor/widgets/TabsWidgetMeta'
import { TextWidgetMeta } from 'features/views/LayoutEditor/widgets/TextWidgetMeta'

export async function computeDetailViewSchema(
    flags: StackerLDFlagSet
): Promise<LayoutEditorSchema> {
    const schema: LayoutEditorSchema = {
        version: 1,
        widgets: {
            text: TextWidgetMeta,
            section: SectionWidgetMeta,
            fields: FieldsWidgetMeta,
            tabs: TabsWidgetMeta,
            listView: ListViewWidgetMeta,
            feed: FeedWidgetMeta,
            divider: DividerWidgetMeta,
            banner: BannerWidgetMeta,
            embed: EmbedWidgetMeta,
        },
    }

    if (flags.agents) {
        schema.widgets.agent = AgentWidgetMeta
    }

    if (flags.pipelineWidget) {
        schema.widgets.pipeline = PipelineWidgetMeta
    }

    if (flags.linkBlocksWidget) {
        schema.widgets.linkBlocks = LinkBlocksWidgetMeta
    }

    return schema
}
