import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { ActionsContextProvider } from 'features/actions/ActionsContextProvider'
import { ObjectFieldsFilterV4 as Filters } from 'features/records/components/RecordFilters'
import { toYType } from 'features/utils/useYjsState'
import {
    DetailViewTabControl,
    DetailViewTabControlItem,
} from 'features/views/DetailView/DetailViewTabControl'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'
import { WidgetAdminControlsComponent, WidgetComponent } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import useDebounce from 'v2/ui/utils/useDebounce'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Field, InfoMark } from 'ui/components/Field'
import { Icon } from 'ui/components/Icon'
import { Input } from 'ui/components/Input'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Skeleton } from 'ui/components/Skeleton'
import { Body, Headline } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import { useSectionWidgetActionButtonState } from './hooks/useSectionWidgetActionButtonState'
import { useSectionWidgetActionsState } from './hooks/useSectionWidgetActionsState'
import { useSectionWidgetState } from './hooks/useSectionWidgetState'
import { FIRST_COLUMN_ID, SECOND_COLUMN_ID } from './sectionWidgetConstants'
import { SectionWidgetType } from './sectionWidgetTypes'
import { moveWidgetsToFirstColumn } from './sectionWidgetUtils'

import {
    SectionWidgetContentStyles,
    SectionWidgetHeaderStyle,
    SectionWidgetWrapperStyles,
} from './SectionWidget.css'

type SectionWidgetProps = {}

export const SectionWidget: WidgetComponent<SectionWidgetType, SectionWidgetProps> = ({
    widget,
    isEditing,
}) => {
    const { title, layout = '100', isHighlighted } = widget.attrs

    const { isOpen, setIsOpen, isCollapsible, actionButtonsPlacement, isVisible } =
        useSectionWidgetState(widget)

    if (!isVisible) return null

    return (
        /* @ts-expect-error: TS doesn't determine the correct type for the Collapsible component. */
        <Box py="l" as={isCollapsible ? Collapsible : 'div'} open={isOpen} onOpenChange={setIsOpen}>
            <Box className={SectionWidgetWrapperStyles.styleFunction({ isHighlighted })}>
                <Box flex column gap="l" py={isHighlighted ? 'l' : 0}>
                    {(isCollapsible || !!title) && (
                        <Box
                            as={isCollapsible ? CollapsibleTrigger : 'div'}
                            className={SectionWidgetHeaderStyle}
                            justifyContent={!!title ? 'space-between' : 'flex-end'}
                        >
                            {title && (
                                <Headline size="xs" color="text" minWidth={0}>
                                    {title}
                                </Headline>
                            )}
                            <Box flex center noShrink gap="m">
                                {actionButtonsPlacement === 'top' && (
                                    <SectionWidgetActions widget={widget} />
                                )}
                                {isCollapsible && (
                                    <Icon
                                        size="xl"
                                        name={isOpen ? 'ChevronUp' : 'ChevronDown'}
                                        color="icon"
                                        noShrink
                                        display="block"
                                        width="2xl"
                                        height="2xl"
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box
                        as={isCollapsible ? CollapsibleContent : 'div'}
                        display="grid"
                        className={SectionWidgetContentStyles.styleFunction({ layout, isEditing })}
                        // @ts-expect-error: TS doesn't determine the correct type for the CollapsibleContent component.
                        animation="fade"
                    >
                        <LayoutEditorWidgetArea minWidth={0} maxWidth="full" id={FIRST_COLUMN_ID} />
                        {layout !== '100' && (
                            <LayoutEditorWidgetArea
                                minWidth={0}
                                maxWidth="full"
                                id={SECOND_COLUMN_ID}
                            />
                        )}
                    </Box>
                    {actionButtonsPlacement === 'bottom' && (
                        <SectionWidgetActions widget={widget} />
                    )}
                </Box>
            </Box>
        </Box>
    )
}

type SectionWidgetActionsProps = {
    widget: SectionWidgetType
}

const SectionWidgetActions: React.FC<SectionWidgetActionsProps> = ({ widget }) => {
    const { visibleActions, menuActions, isLoading, gridTemplateColumns, minWidth } =
        useSectionWidgetActionsState(widget)

    return (
        <Box
            display="grid"
            gap="s"
            style={{
                gridTemplateColumns,
                minWidth,
            }}
        >
            {visibleActions.map((action, idx) => (
                <Skeleton key={action._sid} isLoading={isLoading}>
                    <SectionWidgetActionButton
                        key={action._sid}
                        action={action}
                        size="s"
                        variant={idx === 0 ? 'primary' : 'secondary'}
                    />
                </Skeleton>
            ))}
            {menuActions.length > 0 && (
                <Skeleton isLoading={isLoading}>
                    <Dropdown>
                        <DropdownButton
                            size="s"
                            variant="secondary"
                            startIcon={{ name: 'MoreHorizontal' }}
                            noShrink
                        />
                        <DropdownContent align="end" side="bottom" style={{ minWidth: '286px' }}>
                            {menuActions.map((action) => (
                                <SectionWidgetActionDropdownItem
                                    key={action._sid}
                                    action={action}
                                    closeOnSelect
                                />
                            ))}
                        </DropdownContent>
                    </Dropdown>
                </Skeleton>
            )}
        </Box>
    )
}

type SectionWidgetActionButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
    action: ActionDto
}

export const SectionWidgetActionButton: React.FC<SectionWidgetActionButtonProps> = ({
    action,
    ...props
}) => {
    const { handleClick } = useSectionWidgetActionButtonState({ action })

    return (
        <Button {...props} onClick={handleClick}>
            <Box
                trim
                maxWidth="full"
                style={{
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    marginTop: '-2px',
                    marginBottom: '-2px',
                }}
            >
                {action.name}
            </Box>
        </Button>
    )
}

type SectionWidgetActionDropdownItemProps = React.ComponentPropsWithoutRef<typeof DropdownItem> & {
    action: ActionDto
}

export const SectionWidgetActionDropdownItem: React.FC<SectionWidgetActionDropdownItemProps> = ({
    action,
    ...props
}) => {
    const { handleClick } = useSectionWidgetActionButtonState({ action })

    return <DropdownItem label={action.name} {...props} onClick={() => handleClick()} />
}

type SectionWidgetAdminControlsProps = {}

export const SectionWidgetAdminControls: WidgetAdminControlsComponent<
    SectionWidgetType,
    SectionWidgetAdminControlsProps
> = ({ widget, onChange, ...props }) => {
    return (
        <DetailViewTabControl>
            <DetailViewTabControlItem value="content" label="Content">
                <SectionContentControls widget={widget} onChange={onChange} {...props} />
            </DetailViewTabControlItem>
            <DetailViewTabControlItem value="style" label="Style">
                <SectionStyleControls widget={widget} onChange={onChange} {...props} />
            </DetailViewTabControlItem>
            <DetailViewTabControlItem value="controls" label="Controls">
                <SectionActionControls widget={widget} onChange={onChange} {...props} />
            </DetailViewTabControlItem>
        </DetailViewTabControl>
    )
}

type SectionContentControlsProps = {}

const SectionContentControls: WidgetAdminControlsComponent<
    SectionWidgetType,
    SectionContentControlsProps
> = ({ widget, onChange }) => {
    const { object, fields } = useLayoutEditorContext()

    const { visibilityFilters = [] } = widget.attrs

    const onChangeVisibilityFilters = useCallback(
        (filters: Filter[]) => {
            onChange((attrs) => attrs.set('visibilityFilters', toYType(filters)))
        },
        [onChange]
    )

    return (
        <Box flex column height="full" gap="l">
            <LayoutEditorCollapsibleControl
                label="Conditional visibility"
                startIcon={{ name: 'Eye' }}
                defaultOpen={true}
            >
                <Body size="s" mb="l" display="block">
                    Conditional visibility lets you show and hide an element, based on a filter.
                    This only changes the appearance of the app, and is not a security feature.
                </Body>
                <Filters
                    object={object}
                    value={visibilityFilters}
                    showRoleFilter
                    fields={fields}
                    onChange={onChangeVisibilityFilters}
                    withHeader={false}
                    place="bottom-end"
                    hideTheRecordFilter={false}
                    customButtonRender={(props) => (
                        <Box>
                            <Button
                                {...props}
                                size="2xs"
                                variant="primary"
                                startIcon={{ name: 'Plus' }}
                            >
                                Add filter
                            </Button>
                        </Box>
                    )}
                />
            </LayoutEditorCollapsibleControl>
        </Box>
    )
}

const DEBOUNCE_RATE = 300 // ms

type SectionStyleControlsProps = {}

const SectionStyleControls: WidgetAdminControlsComponent<
    SectionWidgetType,
    SectionStyleControlsProps
> = ({ widget, onChange }) => {
    const {
        title = '',
        layout = '100',
        isHighlighted = false,
        isCollapsible = false,
        startCollapsed = false,
    } = widget.attrs

    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const [localTitle, setLocalTitle] = useState(title)
    const onChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setLocalTitle(value)
            debouncedOnChange((attrs) => {
                attrs.set('title', value)
            })
        },
        [debouncedOnChange]
    )

    const onChangeLayout = useCallback(
        (value: string) => {
            onChange((attrs) => {
                attrs.set('layout', value)
            })
        },
        [onChange]
    )

    const onChangeIsHighlighted = useCallback(
        (value: boolean) => {
            onChange((attrs) => {
                attrs.set('isHighlighted', value)
            })
        },
        [onChange]
    )

    const onChangeIsCollapsible = useCallback(
        (value: boolean) => {
            onChange((attrs) => {
                attrs.set('isCollapsible', value)
            })
        },
        [onChange]
    )

    const onChangeStartCollapsed = useCallback(
        (value: boolean) => {
            onChange((attrs) => {
                attrs.set('startCollapsed', value)
            })
        },
        [onChange]
    )

    const { selection, commands } = useLayoutEditorContext()

    const isSidebar = selection?.layoutArea === 'sidebar'

    const hasConfigDisabled = useMemo(() => {
        if (isSidebar) {
            return true
        }

        return selection?.widgetPath.some((w) => w.type === 'section')
    }, [isSidebar, selection?.widgetPath])

    // If the section widget has a section parent, we need to force some changes.
    useEffect(() => {
        if (hasConfigDisabled) {
            onChangeLayout('100')
            onChangeIsHighlighted(false)
        }
    }, [hasConfigDisabled, onChange, onChangeLayout, onChangeIsHighlighted])

    const prevLayout = useRef(layout)
    useEffect(() => {
        // If we switch to 100% layout, move all other widgets to the first column.
        if (prevLayout.current !== '100' && layout === '100') {
            moveWidgetsToFirstColumn(commands)
        }
        prevLayout.current = layout
    }, [commands, layout])

    return (
        <Box px="l" flex column height="full" gap="3xl">
            <Field
                label="Config"
                leftSlotContent={
                    hasConfigDisabled && (
                        <InfoMark size="xs" zIndex={1500}>
                            {isSidebar
                                ? 'Sidebar sections only support 100% layout'
                                : 'Nested sections only support 100% layout'}
                        </InfoMark>
                    )
                }
                startIcon={{
                    name: 'LayoutTemplate',
                }}
            >
                <RadioCardGroup
                    value={layout}
                    onValueChange={onChangeLayout}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, 67px)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard
                        value="100"
                        icon={() => (
                            <SectionStyleControlsLayoutIcon
                                isActive={layout === '100'}
                                layout="100"
                            />
                        )}
                    >
                        100
                    </RadioCard>
                    <RadioCard
                        disabled={hasConfigDisabled}
                        value="50/50"
                        icon={() => (
                            <SectionStyleControlsLayoutIcon
                                isActive={layout === '50/50'}
                                layout="50/50"
                            />
                        )}
                    >
                        50/50
                    </RadioCard>
                    <RadioCard
                        disabled={hasConfigDisabled}
                        value="70/30"
                        icon={() => (
                            <SectionStyleControlsLayoutIcon
                                isActive={layout === '70/30'}
                                layout="70/30"
                            />
                        )}
                    >
                        70/30
                    </RadioCard>
                    <RadioCard
                        disabled={hasConfigDisabled}
                        value="30/70"
                        icon={() => (
                            <SectionStyleControlsLayoutIcon
                                isActive={layout === '30/70'}
                                layout="30/70"
                            />
                        )}
                    >
                        30/70
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Input label="Title" value={localTitle} onChange={onChangeTitle} />
            <Field
                htmlFor="isHighlighted"
                label="Highlighted"
                startIcon={{ name: 'Highlighter' }}
                leftSlotContent={
                    hasConfigDisabled && (
                        <InfoMark size="xs" zIndex={1500}>
                            {isSidebar
                                ? 'This style is not supported for sidebar sections'
                                : 'This style is not supported for nested sections'}
                        </InfoMark>
                    )
                }
                rightSlotContent={
                    <Toggle
                        id="isHighlighted"
                        checked={isHighlighted}
                        onCheckedChange={onChangeIsHighlighted}
                        disabled={hasConfigDisabled}
                    />
                }
            />
            <Box flex column gap="m">
                <Field
                    htmlFor="collapsible"
                    label="Collapsible"
                    startIcon={{ name: 'ChevronDownCircle' }}
                    rightSlotContent={
                        <Toggle
                            id="collapsible"
                            checked={isCollapsible}
                            onCheckedChange={onChangeIsCollapsible}
                        />
                    }
                />
                {isCollapsible && (
                    <Box flex center justifyContent="space-between">
                        <Box as="label" htmlFor="startCollapsed" cursor="pointer">
                            <Body size="s">Start collapsed</Body>
                        </Box>
                        <Toggle
                            id="startCollapsed"
                            checked={startCollapsed}
                            onCheckedChange={onChangeStartCollapsed}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

type SectionStyleControlsLayoutIconProps = {
    isActive: boolean
    layout: SectionWidgetType['attrs']['layout']
}

const SectionStyleControlsLayoutIcon: React.FC<SectionStyleControlsLayoutIconProps> = ({
    isActive,
    layout,
}) => {
    let gridTemplateColumns = '1fr'
    switch (layout) {
        case '50/50':
            gridTemplateColumns = '1fr 1fr'
            break
        case '70/30':
            gridTemplateColumns = '0.7fr 0.3fr'
            break
        case '30/70':
            gridTemplateColumns = '0.3fr 0.7fr'
            break
    }

    return (
        <Box
            style={{
                width: '30px',
                height: '21px',
                display: 'grid',
                gridTemplateColumns,
                gap: '2px',
            }}
        >
            <Box
                width="full"
                height="full"
                background={isActive ? 'theme100' : 'gray200'}
                style={{ borderRadius: '1px', transition: theme.transition.colors }}
            />
            {layout !== '100' && (
                <Box
                    width="full"
                    height="full"
                    background={isActive ? 'theme100' : 'gray200'}
                    style={{ borderRadius: '1px', transition: theme.transition.colors }}
                />
            )}
        </Box>
    )
}

type SectionActionControlsProps = {}

const SectionActionControls: WidgetAdminControlsComponent<
    SectionWidgetType,
    SectionActionControlsProps
> = ({ widget, onChange }) => {
    const { actionButtonsPlacement = 'hide', actionButtons = [] } = widget.attrs

    const { object } = useLayoutEditorContext()

    const onChangeActionButtonsPlacement = useCallback(
        (value: string) => {
            onChange((attrs) => {
                attrs.set('actionButtonsPlacement', value)
            })
        },
        [onChange]
    )

    const onChangeActionButtons = useCallback(
        ({ pageButtons }: { pageButtons: ActionButton[] }) => {
            onChange((attrs) => {
                attrs.set('actionButtons', toYType(pageButtons))
            })
        },
        [onChange]
    )

    if (!object) return null

    return (
        <Box px="l" flex column height="full" gap="3xl">
            <Field label="Action buttons placement">
                <RadioCardGroup
                    value={actionButtonsPlacement}
                    onValueChange={onChangeActionButtonsPlacement}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, auto-fill)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard value="hide" icon={{ name: 'EyeOff' }}>
                        Hide
                    </RadioCard>
                    <RadioCard value="top" icon={{ name: 'ArrowUpFromLine' }}>
                        Top
                    </RadioCard>
                    <RadioCard value="bottom" icon={{ name: 'ArrowDownToLine' }}>
                        Bottom
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Box style={{ margin: '-8px 0 -16px 0' }}>
                <ActionsContextProvider object={object}>
                    <ActionButtonsSelector
                        object={object}
                        selectedButtons={actionButtons}
                        setConfig={onChangeActionButtons}
                        showHeader={true}
                        canDeleteActions={true}
                        canEditActions={true}
                        canSearchActions={true}
                        canReorderActions={true}
                        animate={false}
                        height="auto"
                        maxHeight="none"
                        customHeader={({ onCreateActionClick }) => (
                            <Box flex center justifyContent="space-between" mb="m">
                                <Body size="m" weight="medium">
                                    Select actions
                                </Body>
                                <Tooltip
                                    asChild
                                    zIndex={1500}
                                    side="bottom"
                                    align="end"
                                    content="Create new action"
                                >
                                    <Button
                                        variant="secondary"
                                        size="xs"
                                        onClick={onCreateActionClick}
                                        startIcon={{ name: 'Plus' }}
                                        aria-label="Create new action"
                                    />
                                </Tooltip>
                            </Box>
                        )}
                    />
                </ActionsContextProvider>
            </Box>
        </Box>
    )
}
