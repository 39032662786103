import { useActionButtons } from 'features/actions/actionHooks'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { SectionWidgetType } from 'features/views/LayoutEditor/widgets/sectionWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

const MAX_VISIBLE_ACTIONS_TABLET = 2
const MAX_VISIBLE_ACTIONS_TOP_MOBILE = 0
const MAX_VISIBLE_ACTIONS_BOTTOM_MOBILE = 2

export function useSectionWidgetActionsState(widget: SectionWidgetType) {
    const { actionButtonsPlacement = 'hide' } = widget.attrs

    const { object } = useLayoutEditorContext()
    const { record, isFetchingSlow } = useRecordManagerContext()

    const actionButtons = widget.attrs.actionButtons ?? []
    const actions = useActionButtons(actionButtons, record!, object!).map((a) => a.action)

    const maxVisibleActions = useResponsiveValue({
        mobile:
            actionButtonsPlacement === 'top'
                ? MAX_VISIBLE_ACTIONS_TOP_MOBILE
                : MAX_VISIBLE_ACTIONS_BOTTOM_MOBILE,
        tablet: MAX_VISIBLE_ACTIONS_TABLET,
    })

    const visibleActions = actions.slice(0, maxVisibleActions)
    const menuActions = actions.slice(maxVisibleActions)

    const menuButtonOffset = !!menuActions.length ? 1 : 0

    const gridTemplateColumns = useResponsiveValue({
        mobile: `repeat(${visibleActions.length}, 1fr) ${menuButtonOffset ? 'minmax(0, max-content)' : ''}`,
        tablet: `repeat(${visibleActions.length + menuButtonOffset}, minmax(10px, max-content))`,
    })
    const minWidth = `${(visibleActions.length + menuButtonOffset) * 32}px`

    return useDeepEqualsMemoValue({
        visibleActions,
        menuActions,
        isLoading: isFetchingSlow,
        gridTemplateColumns,
        minWidth,
    })
}
